<template>
  <div class="room-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>
<script>
//房态:未绑锁
const ROOM_STATE_UNBIND_LOCK = 8
let validRoomNo = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    //仅支持输入数字、汉字，大小写字母，限制字符长度1~24个字符
    let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,24}$/;
    if (!reg.test(value)) {
      callback(new Error("房号格式不对"));
    } else {
      callback();
    }
  }
};
export default {
  data() {
    let _this = this;
    return {
      dialogVisible: false,
      opt: {
        title: "房间列表",
        search: [{
          key: "rentalStyle",
          label: "租赁模式",
          type: "select-no-filterable",
          value: "0",
          opt: {
            list: [{
              label: "长租",
              value: "0"
            },
            {
              label: "短租",
              value: "1"
            },
            {
              label: "钟点房",
              value: "3"
            },
            ]
          }
        },
        {
          key: "apartmentId",
          label: "房源",
          type: "select",
          opt: {
            list: [],
            change(data,cb) {
              _this.opt.search.forEach(item => {
                if(item.key == 'buildingNo') {
                  cb({
                    value: "",
                    key: item.key,
                  })
                  item.opt.list = [];
                }
              })
               _this.getBuildList(data); 
            }
          }
        },
        {
          key: "buildingNo",
          label: "楼栋",
          type: "select",
          opt: {
            list: [],
            parent: "apartmentId",
          }
        },
        {
          key: "roomNo",
          label: "房号",
          maxlength: 24,
          rules: [{
            validator: validRoomNo,
            trigger: ['blur']
          }]
        },
        {
          key: "roomType",
          label: "房型",
          type: "select-no-filterable",
          opt: {
            list: []
          }
        },
        {
          key: "state",
          label: "房间状态",
          type: "select-no-filterable",
          opt: {
            list: [{
              label: "全部",
              value: ""
            },
            {
              label: "未启用",
              value: "0"
            },
            {
              label: "已出租",
              value: "1"
            },
            {
              label: "未出租",
              value: "2"
            },
            //  {
            //   label: "退房中",
            //   value: "3"
            // },
            {
              label: "已锁定",
              value: "5"
            },
            {
              label: "已下架",
              value: "N"
            },
            {
              label: "未绑锁",
              value: `${ROOM_STATE_UNBIND_LOCK}`
            },
            ]
          }
        }
        ],
        columns: [{
          label: "房号",
          key: "roomNo",
          width: 120,
          on: row => {
            let pathUrl = "/main/room/detail/show/"
            if(row.apartmentType == 7) {
              pathUrl = "/main/room/schoolDetail/show/"
            }
            _this.$router.push({
              path: pathUrl + row.id + "?apartmentId=" + row.apartmentId,
              query: {
                apartmentType: row.apartmentType,
              }
            })
          }
        },
        {
          label: "楼栋名称",
          key: "buildName"
        },
        {
          label: "房源",
          key: "apartmentName"
        },
        {
          label: "租赁模式",
          key: "rentalStyleStr"
        },
        {
          label: "房型",
          key: "roomTypeStr"
        },
        {
          label: "楼层",
          key: "floor"
        },
        {
          label: "租金(元/月)",
          key: "rentalFee"
        },
        {
          label: "押金(元)",
          key: "deposit"
        },
        {
          label: "物业费(元/月)",
          key: "propertyFeeStr"
        },
        {
          label: "配套",
          key: "facilitiesStr"
        },
        {
          label: "房态",
          key: "stateStr"
        },
        {
          label: "操作",
          key: "action",
          type: "action-back",
          opt: {
            list: [{
              label: "下架",
              on(row, cb) {
                _this.dialogVisible = true;

                const h = _this.$createElement;
                _this
                  .$msgbox({
                    title: "确定提示",
                    message: h("p", null, [
                      h(
                        "span", {
                        style: "color: #333;font-size: 16px;margin-left:98px"
                      },
                        "确定要下架该房间吗？"
                      ),
                      h(
                        "p", {
                        style: "font-size:14px;margin-left:98px"
                      },
                        "下架后房间不可以出租 "
                      )
                    ]),
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                  })
                  .then(action => {
                    var dto = {
                      roomId: row.id,
                      disable: true,
                      roomInfo: row.apartmentName+row.buildName+row.roomNo
                    };
                    _this
                      .post("landlord-service/room/isDisableRoom", dto, {
                        isUseResponse: true,
                        isNotShowError: true
                      })
                      .then(res => {
                        if (res.data.code == 0) {
                          //下架 重新发布 办理排房 办理订房
                          row.action = [false, false, false, false];
                          row.enabled = "N";
                          //已下架房间标志
                          if (row.enabled == "N") {
                            row.stateStr = "已下架";
                            //未绑锁不支持重新发布
                            row.action[1] = row.state == ROOM_STATE_UNBIND_LOCK ? false : true;
                          } else {
                            //未出租/未绑锁
                            if (row.state == 2 || row.state == ROOM_STATE_UNBIND_LOCK) {
                              if (row.enabled == "Y") {
                                //未绑锁不支持下架
                                row.action[0] = row.state == ROOM_STATE_UNBIND_LOCK ? false : true;
                              }
                              if (_this.cache.getLS("userInfo")["roleType"] != "1") {
                                //房东
                                if (row.isPlan == "1" || item.isPlan == "2") {
                                  row.action[2] = true
                                }
                              }
                            }
                          }
                          cb({
                            data: row,
                            msg: "下架房间成功!",
                            result: true,
                          });
                        }
                        // _this.$message({
                        //   showClose: true,
                        //   message: "下架房间成功!",
                        //   type: "success"
                        // });
                      }).catch(res => {
                        cb({
                          result: false,
                          msg: res.data.msg
                        });
                      });

                    // _this.$refs.myList.onRefresh();
                  });
              }
            },
            {
              label: "重新发布",
              on(row, cb) {
                _this.dialogVisible = true;
                const h = _this.$createElement;
                _this
                  .$msgbox({
                    title: "确定提示",
                    message: h("p", null, [
                      h(
                        "span", {
                        style: "color: #333;font-size: 16px;margin-left:98px"
                      },
                        "确定要重新发布该房间吗？"
                      ),
                      h(
                        "p", {
                        style: "font-size:14px;margin-left:98px"
                      },
                        "重新发布后房间可出租"
                      )
                    ]),
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                  })
                  .then(action => {
                    var dto = {
                      roomId: row.id,
                      disable: false,
                      roomInfo: row.apartmentName+row.buildName+row.roomNo
                    };
                    _this
                      .post("landlord-service/room/isDisableRoom", dto, {
                        isUseResponse: true,
                        isNotShowError: true
                      })
                      .then(res => {
                        //下架 重新发布 办理排房 办理订房
                        if (res.data.code == 0) {
                          row.stateStr = _this.getRoomStateDescList()[Number(row.state)];
                          //下架 重新发布 办理排房 办理订房
                          row.action = [false, false, false, false];
                          row.enabled = "Y";
                          //已下架房间标志
                          if (row.enabled == "N") {
                            row.stateStr = "已下架";
                            //未绑锁不支持重新发布
                            row.action[1] = row.state == ROOM_STATE_UNBIND_LOCK ? false : true;
                          } else {
                            //未出租/未绑锁
                            if (row.state == 2 || row.state == ROOM_STATE_UNBIND_LOCK) {
                              if (row.enabled == "Y") {
                                //未绑锁不支持下架
                                row.action[0] = row.state == ROOM_STATE_UNBIND_LOCK ? false : true;
                              }
                              if (_this.cache.getLS("userInfo")["roleType"] != "1") {
                                //房东
                                if (row.isPlan == "1" || item.isPlan == "2") {
                                  row.action[2] = true
                                }
                              }
                            }
                          }
                          cb({
                            data: row,
                            msg: "发布房间成功!",
                            result: true,
                          });
                        }

                        // _this.$message({
                        //   showClose: true,
                        //   message: "发布房间成功！",
                        //   type: "success"
                        // });
                      }).catch(res => {
                        cb({
                          result: false,
                          msg: res.data.msg
                        });
                      });

                    // _this.$refs.myList.onRefresh();
                  });
              }
            },
            {
              label: "办理排房",
              on(row) {
                // _this.$router.push(
                //   "/main/room/Rowhouse/show/" +
                //     row.id +
                //     "?Rowhouse=" +
                //     JSON.stringify(row)
                // );
                _this.$router.push(
                  "/main/room/Rowhouse/show/" +
                  row.id
                );
                _this.cache.setLS("Rowhouse", row)
              }
            },
            {
              label: "办理订房",
              on(row) { }
            }
            ]
          }
        }
        ],
        buttons: [{
          type: 0,
          on() {
            if(_this.getSchoolLandlordFlag()) {
              _this.$router.push("/main/room/schoolDetail/add/0");
            }else {
              _this.$router.push("/main/room/detail/add/0");
            }
          }
        },
        {
          type: 0,
          name: "批量添加",
          icon: "el-icon-circle-plus-outline",
          on() {
            if(_this.getSchoolLandlordFlag()) {
              _this.$router.push("/main/room/schoolBatch/add/0");
            }else {
              _this.$router.push("/main/room/batch/add/0");
            }
            
          }
        },
        {
          type: 1,
          on(data) {
            let pathUrl = "/main/room/detail/edit/"
            if(data.data.apartmentType == 7) {
              pathUrl = "/main/room/schoolDetail/edit/"
            }
            _this.$router.push({
              path: pathUrl + data.id,
              query: {
                apartmentType: data.data.apartmentType,
              }
            })
          }
        },
        {
          type: 2,
          on(data) {
            const h = _this.$createElement;
            _this
              .$msgbox({
                title: "删除提示",
                message: h("p", null, [
                  h(
                    "span", {
                    style: "color: #333;font-size: 16px;margin-left:98px"
                  },
                    "确定要删除房间吗？"
                  ),
                  h(
                    "p", {
                    style: "font-size:14px;margin-left:98px"
                  },
                    "删除房间后，房间将不可见"
                  )
                ]),
                showCancelButton: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消"
              })
              .then(action => {
                let url = "";
                let dto;
                if(data.list.length > 1) {
                  let notDeleteRoomFalge = false;
                  data.list.find(it => {
                    return notDeleteRoomFalge = !(Number(it.state) == 0 || Number(it.state) == 8);
                  })
                  if(notDeleteRoomFalge) {
                    return _this.$message({
                      message: "房间在未启用、未绑锁的条件下才支持批量删除。",
                      type: "warning"
                    })
                  }
                  url = "landlord-service/room/batch-delete";
                  let obj = []
                  data.ids.forEach(item=>{
                    obj.push({roomId:item})
                  })
                  for (let i = 0; i < obj.length; i++) {
                    obj[i].roomInfo = data.list[i].apartmentName+data.list[i].buildName+data.list[i].roomNo
                  }
                  dto = obj;
                }else {
                  url = "landlord-service/room/delete";
                  dto = {
                    id: data.ids[0],
                    roomInfo: data.list[0].apartmentName+data.list[0].buildName+data.list[0].roomNo
                  };
                }
                _this.post(url, dto).then(function () {
                    _this.$message({
                        showClose: true,
                        message: "删除房间成功！",
                        type: "success"
                    });
                    data.refresh();
                });
              });
          }
        }
          // {
          //   type: 2,
          //   on(data) {
          //     var dto = {
          //       ids: data.ids
          //     };
          //     _this.post("/user/del", dto).then(function() {
          //       _this.$message({
          //         showClose: true,
          //         message: "删除成功!",
          //         type: "success"
          //       });
          //       data.refresh();
          //     });
          //   }
          // }
        ]
      },
      apartmentList: {}, // 房源列表
      typeIdList: {}, // 房型列表
      myColums: [],
      nowRentalStyleVal: 0, //当前选择的租赁模式
    };
  },
  created() {
    console.log("room-list created!!");
    // 学校商户类型不展示编辑按钮
    if(this.getSchoolLandlordFlag()) {
      this.opt.buttons.splice(2, 1);
    }
  },
  deactivated() {
    this.nowRentalStyleVal = 0;
  },
  activated() {
    this.getMenuName(this.$route.path)
    this.myColums = [];
    for (let index in this.opt.columns) {
      this.myColums.push(this.opt.columns[index]["key"]);
    }
    // 学校商户类型不展示 租金 押金 物业费 配套 下架操作;不展示租赁模式的查询条件
    if(this.getSchoolLandlordFlag()) {
      // 不展示租赁模式的查询条件
      this.opt.search.forEach((item,index) => {
        if(item.key == 'rentalStyle') {
          this.opt.search.splice(index, 1);
        }
      })
      // 不展示 租金 押金 物业费 配套 下架操作
      this.opt.columns.forEach((item,index) => {
        if(item.key == "rentalFee") {
          this.opt.columns.splice(index, 1);
        }
        if(item.key == "propertyFeeStr") {
          this.opt.columns.splice(index, 1);
        }
        
      })
      this.opt.columns.forEach((item,index) => {
        if(item.key == "deposit") {
          this.opt.columns.splice(index, 1);
        }
      })
      this.opt.columns.forEach((item,index) => {
        if(item.key == "facilitiesStr") {
          this.opt.columns.splice(index, 1);
        }
      })
      
    }else {
      if (this.nowRentalStyleVal == 1) {
        // 短租 不显示物业费
        this.opt.columns.forEach((item, index) => {
          if (item.key == "propertyFeeStr") {
            this.opt.columns.splice(index, 1);
          }
          if (item.key == "rentalFee") {
            item.label = "租金(元/天)";
            item.key = "shortRentFee";
          }
        })
      } else if (this.nowRentalStyleVal == 0) {
        //长租显示物业费
        if (this.myColums.indexOf("propertyFeeStr") == -1) {
          this.opt.columns.forEach((item, index) => {
            if (item.key == "deposit") {
              this.opt.columns.splice(index + 1, 0, {
                label: "物业费(元/月)",
                key: "propertyFeeStr"
              })
            }
            if (item.key == "shortRentFee") {
              item.label = "租金(元/月)";
              item.key = "rentalFee";
            }
          })
        }
      }
    }
    

    if (this.cache.getLS("userInfo")["roleType"] == "8") //公安账号
    {
      this.opt.columns.forEach((item, index) => {
        if (item.key == "action") {
          this.opt.columns.splice(index, 1);
        }
      })
    }
    this.getApartmentList();
  },

  methods: {
    onGet(opt) {
      this.myColums = [];
      this.nowRentalStyleVal = opt.searchForm.rentalStyle;
      for (let index in this.opt.columns) {
        let item = this.opt.columns;
        this.myColums.push(item[index]["key"]);
        if ( opt.searchForm.rentalStyle == 1 && item[index]["key"] == "rentalFee") {
          item[index]["label"] = "租金(元/天)";
          item[index]["key"] = "shortRentFee";
        } else if ( (opt.searchForm.rentalStyle == 0 || opt.searchForm.rentalStyle == undefined) && (item[index]["key"] == "shortRentFee" || item[index]["key"] == "rentalFee")) {
          item[index]["label"] = "租金(元/月)";
          item[index]["key"] = "rentalFee";
        } else if ( opt.searchForm.rentalStyle == 3 && (item[index]["key"] == "rentalFee" || item[index]["key"] == "shortRentFee")) {
          item[index]["label"] = "租金(元/小时)";
          item[index]["key"] = "rentalFee";
        }
      }
      if (opt.searchForm.rentalStyle == 1 || opt.searchForm.rentalStyle == 3) {
        // 短租 不显示物业费
        this.opt.columns.forEach((item, index) => {
          if (item.key == "propertyFeeStr") {
            this.opt.columns.splice(index, 1);
          }
        })
      } else if (opt.searchForm.rentalStyle == 0) {
        //长租显示物业费
        if (this.myColums.indexOf("propertyFeeStr") == -1) {
          this.opt.columns.forEach((item, index) => {
            if (item.key == "deposit") {
              this.opt.columns.splice(index + 1, 0, {
                label: "物业费(元/月)",
                key: "propertyFeeStr"
              })
            }
          })
        }
      }
      let dto = {
        landlordId: this.cache.getLS("userInfo")["userId"] != 1 ?
          this.cache.getLS("userInfo")["userId"] : null, //获取全局房东id
        pageNumber: opt.skip,
        pageSize: opt.limit,
        rentalStyle: opt.searchForm.rentalStyle ?
          opt.searchForm.rentalStyle : "0",
        apartmentId: opt.searchForm.apartmentId ?
          opt.searchForm.apartmentId : null,
        roomNo: opt.searchForm.roomNo ? opt.searchForm.roomNo : null,
        state: opt.searchForm.state ? opt.searchForm.state : null,
        roomType: opt.searchForm.roomType ? opt.searchForm.roomType : null,
        buildingNo: opt.searchForm.buildingNo ? opt.searchForm.buildingNo : null,
      };
      this.post("landlord-service/leasingCenter/searchPageLandlordRoom", dto, {
        isUseResponse: true
      }).then(res => {
        Promise.all([
          this.getTypeIdList(),
          this.getFacilitiesList()
        ]).then(values => {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.typeStr = ["整租", "合租"][item.type - 1];
            item.elevatorStr = ["有", "无"][item.elevator - 1];
            // item.apartmentStr = values[0][item.apartmentId];
            item.roomTypeStr = values[0][item.roomType];

            // 设置配套
            item.facilitiesStr = [];
            item.facilities = item.facilities ? item.facilities.split(",") : [];
            item.facilities.forEach(data => {
              item.facilitiesStr.push(values[1][data]);
            });
            item.facilitiesStr = item.facilitiesStr.join(",");
            item.rentalStyleStr = ["长租", "短租", "长短租","钟点房"][item.rentalStyle];
            item.stateStr = this.getRoomStateDescList()[Number(item.state)];
            if (opt.searchForm.rentalStyle == 1) {
              item.deposit = item.shortDeposit;
            }
            //下架 重新发布 办理排房 办理订房
            item.action = [false, false, false, false];
            //已下架房间标志
            if (item.enabled == "N") {
              item.stateStr = "已下架";
              //未绑锁不支持重新发布
              item.action[1] = item.state == ROOM_STATE_UNBIND_LOCK ? false : true;
            } else {
              //未出租/未绑锁
              if (item.state == 2 || item.state == ROOM_STATE_UNBIND_LOCK) { //未出租
                if (item.enabled == "Y") {
                  //未绑锁不支持下架
                  item.action[0] = item.state == ROOM_STATE_UNBIND_LOCK ? false : true;
                }
                if (item.isPlan == "1" || item.isPlan == "2") {
                  if(this.getLandlordFlage() || this.getHousekeeperPlanAndQuitFlage()) {//房东 或 有排房操作权限的房管员
                    item.action[2] = true
                  }else {
                    item.action[2] = false
                  }
                }
              }
            }
            if (opt.searchForm.rentalStyle == 3) {
              item.action[2] = false
            }
            if (dto.rentalStyle == "0") {
              item.propertyFeeStr = item.propertyFee;
            }
            // tem.apartmentType == 7为学校 学校类型的房源不显示租金、押金、物业费、配套、下架操作
            if(item.apartmentType == 7) {
              item.rentalFee = '--';
              item.deposit = '--';
              item.propertyFeeStr = '--';
              item.facilitiesStr = '--';
              //下架 重新发布 办理排房 办理订房;学校类型的房源只可以办理排房
              item.action[0] = false;
              item.action[1] = false;
              item.action[3] = false;
            }
            
          }
          opt.cb(res.data);
        });
      });
    },
    //获取房源列表
    getApartmentList() {
        this.opt.search.forEach(item => {
            if(item.key == 'apartmentId') {
                this.getApartment().then(res => {
                    item.opt.list = res;
                })
            }
        })
    },
    // 获取房型
    getTypeIdList() {
      return new Promise((resolve, reject) => {
        this.post("landlord-service/base/getRoomTypeAll", {}).then(res => {
          let returnData = {};
          res.forEach(data => {
            returnData[data.id] = data.name;
          });
          res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
          this.opt.search.forEach(item => {
            if(item.key == 'roomType') {
              item.opt.list = res;
            }
          })
          resolve(returnData);
        });
      });
    },
    // 获取房型配套
    getFacilitiesList() {
      return new Promise((resolve, reject) => {
        this.post("landlord-service/base/getFacilitiesAll", {}).then(res => {
          let returnData = {};
          res.forEach(data => {
            returnData[data.id] = data.name;
          });
          resolve(returnData);
        });
      });
    },
    //房态描述
    getRoomStateDescList() {
      return [
        "未启用",
        "已出租",
        "未出租",
        "退房中",
        "保洁中",
        "已锁定",
        "",
        "",
        "未绑锁",
        "",
        "在租"
      ]
    },
    // 获取楼栋
    getBuildList(data) {
      if(!data) {
        return
      }
      let dto = {
        apartmentId: data
      }
      this.post('/landlord-service/building/list',dto).then(res => {
         res = JSON.parse(JSON.stringify(res).replace(/buildName/g, "label"));
         res = JSON.parse(JSON.stringify(res).replace(/buildCode/g, "value"));
         this.opt.search.forEach(item => {
           if(item.key == 'buildingNo') {
             item.opt.list = res;
           }
         })
      })
    }
  }
};
</script>
<style lang="scss">
.room-list {
  .content-top {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background: rgba(242, 242, 242, 1);
    color: #666666;
    margin-bottom: 20px;
    padding-left: 10px;
    box-sizing: border-box;

    span {
      padding-left: 10px;
      padding-right: 10px;
    }

    label {
      color: #ff0000;
    }
  }

  .content-box {
    height: 620px;

    .form-box {
      height: 530px;
      overflow-y: auto;
      padding-right: 20px;

      .list-box {
        margin: 0 auto;
        max-width: 1200px;

        .list-item {
          padding: 4px 0;
          margin-bottom: 6px;
          background-color: #eee;

          .list-idx {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            color: #606266;
          }

          .list-col {
            display: inline-block;

            label {
              margin-left: 10px;
              margin-right: 6px;
              font-size: 14px;
              color: #606266;
              line-height: 40px;
            }

            .el-input {
              width: 120px;
            }
          }

          .list-buts {
            float: right;
            padding-right: 10px;
          }
        }
      }

      .dashed-box {
        width: 100%;
        border-top: 1px dashed #cccccc;
        margin-top: -12px;
        margin-bottom: 12px;
      }
    }

    .btn-box {
      padding-left: 20px;
      margin-bottom: 20px;
    }

    .description {
      color: #999999;
    }
  }

  .bottom-box {
    width: 100%;
    text-align: center;
  }

  .el-dialog {
    margin-top: 8vh !important;
  }

  .el-time-panel {
    display: none !important;
  }
}
</style>